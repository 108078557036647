<template>
  <div class="class">
    <div class="loading-wrapper" v-show="showLoading">
      <Loading></Loading>
    </div>
    <div class="top">
      <p class="apply" style="font-size: 14px">共<span class="num" :v-model="total">{{total}}</span>个培训班</p>
      <el-input class="inp" v-model="className"></el-input>
      <el-button class="el-but" type="primary" @click="getAllClassByMoHu()" >搜索</el-button>
    </div>
    <el-row class="bg">
    <el-table
      :data="dataList">
      <el-table-column
        label="班级名称"
        width="200px">
        <template slot-scope="scope">
          <span class="spani">{{ scope.row.className}}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="班级人数"
        width="80px" align="center">
        <template slot-scope="scope">
          <span>{{scope.row.classNumber}}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="办班地点"
         align="center">
        <template slot-scope="scope">
          <span class="spani">{{scope.row.trainLocation}}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="课程类别"
        width="140px" align="center">
        <template slot-scope="scope">
          <span class="spani">{{scope.row.category}}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="发布时间"
        width="84px" align="center">
        <template slot-scope="scope">
          <span class="spani">{{scope.row.registerOpenTime}}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="办班机构"
        width="160px" align="center">
        <template slot-scope="scope">
          <span class="spani">{{scope.row.sponsor}}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        width="300"
       >
        <template slot-scope="scope" >
          <el-button class="table-btn" size="mini" type="primary" @click="curriculum(scope.row)">课程管理</el-button>
          <el-button class="table-btn" size="mini" type="primary" @click="curriculumdownLoad(scope.row)">课程考勤</el-button>
          <el-button class="table-btn" size="mini" type="primary" @click="ClassdownLoad(scope.row)">学员考勤</el-button>
          <el-button class="table-btn" size="mini" type="success" v-permission="['Dyaclass:import']" @click="assessment(scope.row.classId)">上传附件</el-button>
          <el-button class="table-btn" v-if="scope.row.scheduleUrl" size="mini" type="primary" v-permission="['Dyaclass:see']" @click="download(scope.row.classId)">查看附件</el-button>
          <el-button class="table-btn" v-if="scope.row.scheduleUrl" size="mini" type="danger" v-permission="['Dyaclass:delete']" @click="removeData(scope.row)">删除附件</el-button>
        </template>
      </el-table-column>
    </el-table>
    </el-row>
    <!-- 课程管理弹窗 -->
    <el-dialog
      title="课程管理"
      :visible.sync="curriculumDialogVisible"
      width="90%"
      center>
      <el-table
      :data="curriculumData">
      <el-table-column
        label="课程日期"
        width="200px">
        <template slot-scope="scope">
          <span class="spani">{{ scope.row.courseDate}}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="开始时间"
        width="80px">
        <template slot-scope="scope">
          <span>{{scope.row.startTime}}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="结束时间"
        width="200px">
        <template slot-scope="scope">
          <span class="spani">{{scope.row.endTime}}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="星期几"
        width="140px">
        <template slot-scope="scope">
          <span class="spani">{{scope.row.week}}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="课程内容"
        width="160px">
        <template slot-scope="scope">
          <span class="spani">{{scope.row.content}}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="讲师"
        width="160px">
        <template slot-scope="scope">
          <span class="spani">{{scope.row.lecturer}}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="职务"
        width="160px">
        <template slot-scope="scope">
          <span class="spani">{{scope.row.duty}}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="单位"
        width="160px">
        <template slot-scope="scope">
          <span class="spani">{{scope.row.unit}}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="教室"
        width="160px">
        <template slot-scope="scope">
          <span class="spani">{{scope.row.classroom}}</span>
        </template>
      </el-table-column>
      </el-table>
    </el-dialog>
    <el-dialog
      title="查看"
      :visible.sync="centerDialogVisibleTwo"
      width="30%"
      center>
      <div v-cloak>
        <span v-if="scheduleUrl == '没有数据' " style="text-align: center">暂时附件下载</span>
        <span v-else><a class="download" :href="scheduleUrl">下载查看附件</a></span>
      </div>
    </el-dialog>
    <el-dialog
      title="附件"
      :visible.sync="centerDialogVisible"
      width="600"
      center>
      <el-form>
        <el-row>
          <el-form-item label="附件：">
            <div class="el-form-item__content">
              <el-upload ref="upload"
                         accept=".xls,.xlsx,.pdf,.doc,.docx,.pptx,.ppt"
                         action="#"
                         :auto-upload="false"
                         :multiple="false"
                         :file-list="fileList"
                         :before-upload="beforeUpload"
                         :http-request="uploadHttpRequest"
                         :on-remove="fileRemove"
                         :on-change="fileChange">
                <el-button size="small" type="primary">选择文件</el-button>
                <!-- <el-button type="primary" size="small" @click="submitUpload">上 传</el-button> -->
                <div slot="tip" class="el-upload__tip">只能上传.xls,.xlsx,.pdf,.doc,.docx,.pptx,.ppt文件，且不超过200M</div>
              </el-upload>
            </div>
          </el-form-item>
        </el-row>
        <el-row><el-button type="primary" size="small" @click="submitUpload">上 传</el-button>
        </el-row>
        <el-row>
          <el-form-item style="text-align:center">
            <el-button @click="centerDialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="uploadBt">确 定</el-button>
          </el-form-item>
        </el-row>
      </el-form>
    </el-dialog>
    <el-dialog
      :visible.sync="Look"
      :title="title"
    ><el-table
      :data="dataList"
    >
      <el-table-column
        label="课程名称"
        width="300px">
        <template slot-scope="scope">
          <span style="margin-left: 10px" class="spani">{{ scope.row.className }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作">
        <template>
          <el-button type="danger">删除</el-button>
          <el-button type="warning">修改</el-button>
          <el-button type="success">打印</el-button>
        </template>
      </el-table-column>
    </el-table></el-dialog>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageNum"
      :page-size="pageSize"
      layout="total,sizes,  prev, pager, next, jumper"
      :total="total"
      class="pag"
    >
    </el-pagination>
  </div>
</template>

<script>
  import { getUrl } from '@/utils/request.js'
  import { getAllClassByMoHu, GetdepartmentId, upload, updWordUrl, delSchedule, cheduleName, downLoadData } from '@/api/user.js'
  import { QR } from '@/api/admin'
  import Loading from '@/components/loading/Loading.vue'
  import { mapMutations } from 'vuex'
  // import axios from 'axios' // 导入方法
  export default {
    name: 'Classinformation',
    components: {
      Loading
    },
    data () { // 数据模型
      return {
        curriculumDialogVisible: false,
        curriculumData: [],
        title: '查看课程',
        year: '',
        url: getUrl() + '/upload/admin',
        Look: false,
        applytstwo: false,
        showLoading: false,
        classId: '',
        dictionaryList4: [],
        rules1: {
        },
        fileList: [],
        orderby: '',
        className: '',
        opentest: {
          trainNumber: ''
        },
        opentestone: {
          registerOpenTime: ''
        },
        pageNum: 1,
        pageSize: 10,
        total: 0,
        pageNumTwo: 1,
        pageSizeTwo: 10,
        totalTwo: 0,
        dataList: [
          {
            classId: '',
            category: '',
            train_location: '',
            id: '',
            className: '',
            train_number: '',
            register_open_time: '',
            sponsor: ''
          }
        ],
        titlee: '填写报名信息',
        applyts: false,
        formLabelwidth: '100px',
        formLabeleft: '100px',
        centerDialogVisible: false,
        scheduleUrl: '',
        centerDialogVisibleTwo: false
      }
    },
    mounted() { // 钩子函数
      this.getAllClassByMoHu()
      this.GetAllById(170) // 部门名称
    },
    computed: {}, // 计算属性
    methods: {
      // 课程考勤导出
      curriculumdownLoad(row) {
        const query = {
            classId: row.classId,
            status: 2
          }
        downLoadData(query).then(resp => {
          console.log(resp)
          const link = document.createElement('a')
          const blob = new Blob([resp.data], { type: 'application/vnd.ms-excel' })
           link.style.display = 'none'
           // 设置连接
           link.href = URL.createObjectURL(blob)
           link.download = row.className + '课程考勤表.xlsx'
           document.body.appendChild(link)
           // 模拟点击事件
           link.click()
        }).catch((err) => {
          console.log(err)
        })
      },
      // 学员考勤导出
      ClassdownLoad(row) {
        const query = {
            classId: row.classId,
            status: 1
          }
        downLoadData(query).then(resp => {
          console.log(resp)
          const link = document.createElement('a')
          const blob = new Blob([resp.data], { type: 'application/vnd.ms-excel' })
           link.style.display = 'none'
           // 设置连接
           link.href = URL.createObjectURL(blob)
           link.download = row.className + '学员考勤表.xlsx'
           document.body.appendChild(link)
           // 模拟点击事件
           link.click()
        }).catch((err) => {
          console.log(err)
        })
      },
      assessment(classId) {
        this.centerDialogVisible = true
        this.classId = classId
      },
      uploadBt() { // 附件作业提交
        const query = {
          classId: this.classId,
          scheduleUrl: this.list.url
        }
        updWordUrl(query).then(resp => {
          if (resp.data.code === '200') {
            this.centerDialogVisible = false
            this.$notify({
              title: '课程提交成功',
              type: 'success'
            })
            this.getAllClassByMoHu()
          }
        })
      },
      // 上传文件之前的钩子：判断上传文件格式、大小等，若返回false则停止上传
      beforeUpload(file) {
        // 文件类型
        var fileType = file.name.substring(file.name.lastIndexOf('.') + 1)
        // 判断文件格式
        if (!(fileType === 'xls' || fileType === 'xlsx' || fileType === 'doc' || fileType === 'docx' || fileType === 'pdf' || fileType === 'pptx' || fileType === 'ppt')) {
          this.$message.error('上传文件只能是xls/xlsx/pdf/doc/docx/pptx/ppt格式！')
        }
        // 文件大小限制为10M
        const fileLimit = file.size / 1024 / 1024 < 200
        if (!fileLimit) {
          this.$message.error('上传文件大小不超过200！')
        }
        return fileType && fileLimit
      },
      // 自定义上传方法，param是默认参数，可以取得file文件信息，具体信息如下图
      uploadHttpRequest(param) {
        const formData = new FormData() // FormData对象，添加参数只能通过append('key', value)的形式添加
        formData.append('file', param.file) // 添加文件对象 要提交给后台的文件
        upload(formData).then(resp => {
          console.log(resp)
          if (resp.data.code === '200') {
            this.$notify({
              title: '成功',
              message: '上传成功',
              type: 'success'
            })
            this.list = resp.data.data
          }
        }).catch(err => {
          console.log('失败', err)
          param.onError() // 上传失败的文件会从文件列表中删除
        })
      },
      // 点击上传：手动上传到服务器，此时会触发组件的http-request
      submitUpload() {
        this.$refs.upload.submit()
      },
      // 文件发生改变
      fileChange(file, fileList) {
        if (fileList.length > 0) {
          this.fileList = [fileList[fileList.length - 1]] // 展示最后一次选择的文件
        }
      },
      // 移除选择的文件
      fileRemove(file, fileList) {
        if (fileList.length < 1) {
          this.uploadDisabled = true // 未选择文件则禁用上传按钮
          this.$emit('submitUpload:visible', false) // 直接修改父组件的属性
        }
      },
      lookover(row) {
        this.Look = true
        const classid = row
        console.log(classid)
      },
      ...mapMutations({
        saveUser: 'SAVE_USER'
      }),
      handleSizeChange(e) {
        this.pageSize = e
        this.getAllClassByMoHu()
      },
      handleCurrentChange(e) {
        this.pageNum = e
        this.getAllClassByMoHu()
      },
      getAllClassByMoHu: function () {
        // const className = this.className
        const query = {
          className: this.className,
          pageNum: this.pageNum,
          pageSize: this.pageSize
        }
        getAllClassByMoHu(query).then(resp => {
          if (resp.data.code === '0') {
            this.total = resp.data.data.total // 查询总条数
            this.dataList = resp.data.data.rows // 接收返回的数据
          }
        })
      },
      QR(row) {
        QR(row.classId).then(resp => {})
      },
      GetAllById (id) {
        GetdepartmentId(id).then(resp => {
          if (resp.data.code === '0') {
            // 将获得到的数据赋值给options
            this.optionsTwo = resp.data.data
            // this.dictionaryList4 = resp.data.data
          }
        })
      },
      removeData(row) {
        // this.classId = classId
        // this.getAllClassByMoHu.splice(classId, 1)
        // console.log(classId)
        this.$confirm('是否删除该班级里的课程表?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then((e) => {
          if (e === 'confirm') {
            delSchedule(row.classId).then(res => {
              if (res.data.code === '200') {
                this.$notify({
                  title: '删除成功',
                  type: 'success'
                })
                const index = this.dataList.indexOf(row)
                this.dataList.splice(index, 1)
                this.total--
                this.getAllClassByMoHu()
              }
            })
          }
        })
      },
      download(classId) { // 查看
        this.classId = classId
        this.centerDialogVisibleTwo = true
        cheduleName(this.classId).then(res => {
          if (res.data.code === '200') {
            this.scheduleUrl = res.data.data
            console.log(this.scheduleUrl)
          }
        })
      },
       curriculum(row) {
         this.$router.push({ path: '/Curriculum', query: { id: row.classId, className: row.className } })
          // this.curriculumDialogVisible = true
          // console.log(row)
          // const query = {
          //   trainId: row.classId
          // }
          // curriculumList(query).then(resp => {
          //   console.log(resp)
          //   if (resp.data.code === '0') {
          //       this.curriculumData = resp.data.data.rows
          //    }
          // })
        }
    }
  }
</script>

<style>
  [v-cloak] {
    display:none !important;
  }
  .bg{margin-top: 10px; padding: 20px; background-color: white;}
  .class{
    width: 100%;
    background-color: white;
    position: relative;
    margin-top: -10px;
  }
  .top{
    width: 100%;
    height: 60px;
    background-color: #e9e9e9;
    display: flex;
    align-items: center;
  }
  .num{
    color: #2c2fd6;
  }
  .apply{
    position: absolute;
    left: 30px;
    margin: 0;
  }
  .inp{
    width: 250px;
    position: absolute;
    left: 180px;
    border: none;
  }
  .el-but{
    position: absolute;
    left: 440px;
  }
  .left-tag p{
    margin-right: 30px;
  }
  .pag{position: absolute;right:10px}
  .big div{flex: 1}
  .download{margin:auto; width: 150px; line-height: 31px; display: block; border-radius:4px;
    color: #FFF; background-color: #409EFF; border-color: #409EFF;text-align:center;
    font-size:12px; text-decoration:none; cursor:pointer;}
  .download:hover{color: #FFF; background-color: #70b3f9; border-color: #70b3f9; text-decoration:none; }
  .table-btn{
    margin: 5px !important;
  }
</style>
